<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <!-- <b-form-group>
        <div class="d-flex align-items-center mt-1">
          <label class="mr-1 h4">Société</label>
          <b-form-radio-group
            v-model="company"
            @change="getInvoices()"
          >
            <b-form-radio
              value="all"
              class="h3"
            >
              All
            </b-form-radio>
            <b-form-radio
              value="doorly"
              class="h3"
            >
              Doorly
            </b-form-radio>
            <b-form-radio
              value="rafika"
              class="h3"
            >
              Rafika Khalfallah
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </b-form-group> -->

      <b-form-group>
        <b-button
          :to="{ name: 'create-invoice' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter facture</span>
        </b-button>
      </b-form-group>
      <b-form-group>
        <b-button
          variant="gradient-primary"
          class="ml-1 d-inline-block"
          @click="printAll"
        >
          <feather-icon icon="PrinterIcon" />
          <span class="ml-1">Imprimer</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      ref="my-table"
      :current-page="currentPage"
      :columns="columns"
      :rows="invoices"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
             <router-link
              class="text-success"
              :to="{ name: 'show-invoice-doorly',
                     params: { id: props.row.id} }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>
            <!-- <router-link
              v-if="props.row.company === 'rafika'"
              class="text-success"
              :to="{ name: 'show-invoice-rafika',
                     params: { id: props.row.id} }"
            > <feather-icon
              :id="`invoice-row-${props.row.id}-showdel-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link> -->
            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
            <span>
              <feather-icon
                :id="`invoice-row-${props.row.id}-trash-icon`"
                icon="TrashIcon"
                color="red"
                class="cursor-pointer"
                size="16"
                @click="deleteOrder(props.row.id)"
              />
              <b-tooltip
                title="Supprimer"
                class="cursor-pointer"
                :target="`invoice-row-${props.row.id}-trash-icon`"
              /></span>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Affichage 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['50', '25', '15']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> de {{ count }} résultats trouvés </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSpinner,
  BTooltip,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    BFormRadio,
    BFormRadioGroup
  },
  data() {
    return {
      count: 0,
      currentPage: 1,
      load: 'false',
      pageLength: 15,
      dir: false,
      company:'all',
      columns: [
        {
          label: 'Reference Facture',
          field: 'number',
        },
        {
          label: 'Client',
          field: 'customer',
        },
        {
          label: 'Date de création',
          field: 'created_at',
          formatFn: this.formatFn,
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      invoices: [],
    }
  },

  created() {
    this.getInvoices(1)
  },
  methods: {
    deleteOrder(invoice) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axiosIns
            .delete(`/invoices/delete/${invoice}/`)
            .then(() => {
              this.load = 'false'
              this.getInvoices(1)
              setTimeout(() => {
                this.showToast(
                  'success',
                  'top-center',
                  'Facture supprimée avec succés',
                )
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Devis Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    printAll() {
      const selected = this.$refs['my-table'].selectedRows
      if (selected.length === 0) {
        this.showToast(
          'warning',
          'top-center',
          'Sélectionner les éléments a imprimé !',
        )
      } else {
        const all = selected.map(x => x.id)
        this.$router.push({ name: 'invoice-print-all', params: { all } })
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },

    async pageNext(currentPage) {
      this.getInvoices(currentPage)
    },

    async getInvoices(currentPage) {
      const { data } = await axiosIns.get('/invoices/', {
        params: { p: currentPage },
      })
      this.invoices = data.results
      this.count = data.count
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
